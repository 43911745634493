.page.error {
    .error-message {
        font-family: monospace;
        font-size: 20px;
        font-weight: 200;
    }

    .error-code {
        font-family: monospace;
        font-size: 100px;
        font-weight: 900;
        line-height: 1.0;
    }

    @media (min-width: 48em) {
        .error-code {
            font-size: 150px;
        }

        .error-message {
            font-size: 30px;
        }
    }

    @media (min-width: 58em) {
        .error-code {
            font-size: 150px;
        }

        .error-message {
            font-size: 40px;
        }
    }

    .error-subtitle {
        font-size: 40px;
        font-weight: 900;
    }

    .error-header {
        margin-bottom: 50px;
    }

    .error-contact-info {
        margin-top: 50px;
    }
}