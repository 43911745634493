.background-glasses {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1000;

    overflow-x: hidden;
    overflow-y: visible;

    .background-glasses-container {
        position: absolute;
        top: 0;
        right: 0;

        img {
            width: 100%;
            opacity: 0.12;
            filter: hue-rotate(112deg);
        }

        width: 400px;
        margin-right: -65px;
        margin-top: 150px;

        @media (min-width: 48em) {
            width: 600px;
            margin-right: -95px;
            margin-top: -20px;
        }

        @media (min-width: 64em) {
            width: 800px;
            margin-right: -130px;
            margin-top: -20px;
        }

        @media (min-width: 96em) {
            width: 1200px;
            margin-right: -200px;
            margin-top: -20px;
        }
    }
}