.sidebar {
    overflow: hidden;
    background-color: unset;
    padding: unset; // cannot pad here because of background-image
    -webkit-transform: translate3d(0,0,0); /* workaround for Mobile Safari bug where the glasses appear above the sidebar */

    .sidebar-content {
        padding: 3rem 1rem; // re-apply padding from sidebar
        color: rgba(255, 255, 255, 0.9); // better readability for logo

        // allow scrolling within this div
        max-height: 100%;
        overflow-y: auto;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 150px;
            filter: drop-shadow(2px 0px 1px  white) drop-shadow(-2px 0px 1px  white) drop-shadow(0px 2px 1px  white) drop-shadow(0px -2px 1px  white);
        }
    }

    .lead {
        text-align: center;
    }

    .sidebar-nav {
        text-align: center;
    }

    $sidebar-background-blur-radius: 10px;

    .sidebar-background {
        position: absolute;
        left: -12px;
        right: -12px;
        top: -12px;
        bottom: -12px;
        z-index: -1;
        background-color: black;
        background-image: url("/assets/images/forest.jpg");
        background-size: cover;
        background-position: center;
        filter: blur($sidebar-background-blur-radius) brightness(0.8);
    }
}


@media (min-width: 48em) {
    .sidebar {
        position: fixed;
        left: 0;
        bottom: 0;
        height: 100vh;
        top: unset;
        width: 16rem;

        .sidebar-nav {
            .sidebar-nav-item {
                font-size: 22px;
            }
        }
    }
}


@media (min-width: 58em) {
    .sidebar {
        .sidebar-nav {
            .sidebar-nav-item {
                font-size: 24px;
            }
        }
    }
}



tbody tr:nth-child(even) td, tbody tr:nth-child(even) th, thead tr td, thead tr th {
    // explicitly fill even rows white so the background doesn't shine through
    // odd rows are filled with #f9f9f9 by Hyde already
    background-color: #ffffff;
}



.no-break {
    white-space: nowrap;
}


.dependable-quote {
    text-align: center;
    font-style: italic;
}


.reference {
    position: relative;
    height: 100%;
    width: 100%;
}


.absolute-full {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


.overflow-y-auto {
    overflow-y: auto;
}

.clearfix::after {
    display: block;
    content: "";
    clear: both;
}
