$margin-between-members: 30px;

.members-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -$margin-between-members;
}

.members-list .member {
    flex: 200px 0 0;
    margin-bottom: $margin-between-members;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
        text-align: center;
    }
}

.members-list .member .picture,.member-page-picture {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.member-page-picture {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
}

.members-list .member .position {
    font-size: 80%;
    line-height: 80%;
}

.members-list:after {
    // make sure last rows don't spread out
    // https://stackoverflow.com/questions/18744164/flex-box-align-last-row-to-grid
    content: "";
    flex: 200px 0 0;
}
