.rightbar {
    overflow-y: auto;
}

@media (min-width: 90em) {
    .rightbar {
        width: 20rem;
        position: fixed;
        right: 0px;
        top: 0px;
        bottom: 0px;
        padding-right: 3em;
        padding-left: 1em;
        overflow-y: hidden;
    }

    .rightbar .rightbar-content {
        padding: 1px 0px; // prevent margin collapsing (https://stackoverflow.com/questions/36444521/css-unwanted-margin-in-parent-div)
    }

    .rightbar .rightbar-cloak {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 200px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95) 15%, rgba(255, 255, 255, 0));
    }

    .rightbar .rightbar-read-more {
        position: absolute;
        bottom: 10px;
        font-weight: 900;
    }

    /* On medium-sized screens, rightbar and the rest of the text would overlap – so rightbar-remainder gains a right margin. */

    @media (max-width: 100em) {
        .rightbar-remainder {
            margin-right: 5em;
        }
    }

    @media (max-width: 95em) {
        .rightbar-remainder {
            margin-right: 10em;
        }
    }

    /* tighter spacing for the rightbar */

    .rightbar h1 {
        font-size: 1.5rem;
    }

    .rightbar h2 {
        font-size: 1.25rem;
    }

    .rightbar .post {
        margin-bottom: 2rem;
    }

    .rightbar .post-date {
        margin-bottom: 0.5rem;
    }

    /* prevent cloaking from covering the glasses  */

    @media (max-height: 600px) {
        .rightbar .rightbar-cloak {
            height: 100px;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95) 30%, rgba(255, 255, 255, 0));
        }
    }

    @media (max-height: 500px) {
        .rightbar .rightbar-cloak {
            visibility: hidden;
        }

        .rightbar .rightbar-read-more {
            // emergency hack to keep the link readable
            text-shadow: 0 0 0.1rem white, 0 0 0.2rem white, 0 0 0.3rem white, 0 0 0.4rem white, 0 0 0.5rem white, 0 0 0.5rem white, 0 0 0.5rem white, 0 0 0.5rem white;
        }
    }
}
