.quotes-introduction {
    margin-bottom: 20px;
}

.quotes-disclaimer {
    margin-bottom: 100px;
}

.quotes {
    .quote {
        margin-bottom: 60px;
    }

    .quote-content {
        margin: 0pt;
        padding: 0.35em 40px;
        line-height: 1.45;
        position: relative;
    }

    .quote-text {
        font-size: 22px;
        font-weight: 600;
        color: #383838;

        p {
            margin-bottom: 5pt;
        }
    }

    .quote-content::before {
        font-family: Georgia, serif;
        display: block;
        padding-left: 10px;
        content: "“";
        font-size: 100px;
        position: absolute;
        left: -25px;
        top: -25px;
        color: #888;
    }

    .quote-content cite {
        color: #777;
        font-size: 18px;
        display: block;
        margin-top: 5px;

        a {
            color: inherit;
        }
    }
    
    .quote-content cite::before {
        content: "— ";
    }

    .quote-author {
        float: right;
        margin-left: 20px;
        margin-bottom: 10px;
        z-index: 100;
        position: relative;
    }

    .quote-author-picture {
        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: center;
    }

    .circumstances {
        margin-top: 20px;
        font-size: 70%;
    }

    .pretext {
        font-size: 70%;
        margin-left: 40px;

        p {
            margin-bottom: 0pt;
        }
    }
}
